const data = [
  {
    id: "1W6WV", 
    title: "Home",
    url: "/",
  
  },
  {
    id: "2ZYYU",
    title: "Weapon Inventory",
    url: "/weapon-inventory",
  },
  {
    id: "ZYUVV",
    title: "GEM Rewards",
    url: "gemrewards",
  },
  {
    id: "ZZUVV",
    title: "Leader Board",
    url: "leaderboard",
  },
];

export default data;
