const data = [
  {
    title: "Total Players",
    value: "64",
    suffix: "",
  },
  {
    title: "Active Player",
    value: "15",
    suffix: "",
  },
  {
    title: "Total hours played",
    value: "1.2",
    suffix: "K",
  },
  {
    title: "Total Bullets Fired",
    value: "54",
    suffix: "K",
  },
  
];

export default data;
