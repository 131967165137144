import FooterBottom from "../FooterBottom/v1";
import shapeLeft from "assets/image/right.png"
import shapeRight from "assets/image/left.png"
import rocketThumb from "assets/image/footerimage.png"
import FooterStyleWrapper from "./footer.style";

const Footer = () => {
  return (

      <FooterBottom />
    
  );
};

export default Footer;
